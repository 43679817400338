





















import { Component, Vue, Prop } from 'vue-property-decorator';
import { FormSubmissionValidationError, FormValue } from 'client-website-ts-library/types/Forms';
import { PriorityListField, IFormField, PriorityListItem } from 'client-website-ts-library/types/Forms/FieldTypes';
import draggable from 'vuedraggable';

interface FormPriorityListFieldItem {
  name: string;
  key: string;
  order: number;
}

@Component({
  components: {
    draggable,
  },
})
export default class FormPriorityListField extends Vue implements IFormField {
  @Prop()
  private readonly def!: PriorityListField;

  @Prop()
  private readonly errors!: FormSubmissionValidationError[];

  private dragging = false;

  private prioritised: PriorityListItem[] = [];

  get hasErrors(): boolean {
    return this.errors.length > 0;
  }

  get elemId() {
    return `field_${this.def.FieldId}`;
  }

  mounted() {
    this.prioritised = this.def.Items;
  }

  getValue(): FormValue {
    return {
      Key: this.def.Key,
      Value: JSON.stringify(this.prioritised),
    };
  }

  setValue(value: FormValue) {
    if (typeof value.Value === 'string') {
      this.prioritised = JSON.parse(value.Value);
    }
  }

  handleInput(): void {
    this.$emit('input', this.def);
  }
}
